import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Header from './Header';
import Resume from './Resume';
import Particles from 'react-particles-js';
import { makeStyles } from '@material-ui/core/styles';

//css styles

const useStyles = makeStyles({
    particlesCanva: {
        position: "absolute",
        opacity:"0.3"
    }
})

const Home = () => {

    const classes = useStyles();

    return (
        <div>
            <>
            <Navbar />
                <Header />
                <Particles
                    canvasClassName={classes.particlesCanva}
                    paramas={{
                        particles: {
                            number: {
                                value: 45,
                                density: {
                                    enable: true,
                                    value_area: 900
                                }
                            },
                            shape: {
                                type: "star",
                                stroke: {
                                    width: 1,
                                    color: 'tomato'
                                }
                            },
                            size: {
                                value: 8,
                                random: true,
                                // animation: false,
                                anim: {
                                    enable: true,
                                    speed: 8,
                                    size_min: 0.1,
                                    sync: true
                                }
                            },
                            opacity: {
                                values: 1,
                                random: true,
                                anim: {
                                    enable: true,
                                    speed: 1,
                                    opacity_min: 0.1,
                                    sync: true
                                }
                            }
                        }
                    }}
                />
            </>
        </div>
    )
}

export default Home;
